export const currencies = [
  {
    code: "GBP",
    flag: "https://tailwindui.com/img/flags/flag-united-kingdom.svg",
  },
  {
    code: "EUR",
    flag: "https://tailwindui.com/img/flags/flag-europe.svg",
  },
];

export const navigation = {
  categories: [
    {
      id: "women",
      name: "Women",
      featured: [
        {
          name: "New Arrivals",
          href: "/category",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg",
          imageAlt:
            "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Basic Tees",
          href: "/category",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg",
          imageAlt:
            "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
        },
      ],
      sections: [
        {
          id: "clothing",
          name: "Clothing",
          items: [
            { name: "Tops", href: "/category" },
            { name: "Dresses", href: "/category" },
            { name: "Pants", href: "/category" },
            { name: "Denim", href: "/category" },
            { name: "Sweaters", href: "/category" },
            { name: "T-Shirts", href: "/category" },
            { name: "Jackets", href: "/category" },
            { name: "Activewear", href: "/category" },
            { name: "Browse All", href: "/category" },
          ],
        },
        {
          id: "accessories",
          name: "Accessories",
          items: [
            { name: "Watches", href: "/category" },
            { name: "Wallets", href: "/category" },
            { name: "Bags", href: "/category" },
            { name: "Sunglasses", href: "/category" },
            { name: "Hats", href: "/category" },
            { name: "Belts", href: "/category" },
          ],
        },
        {
          id: "brands",
          name: "Brands",
          items: [
            { name: "Full Nelson", href: "/category" },
            { name: "My Way", href: "/category" },
            { name: "Re-Arranged", href: "/category" },
            { name: "Counterfeit", href: "/category" },
            { name: "Significant Other", href: "/category" },
          ],
        },
      ],
    },
    {
      id: "men",
      name: "Men",
      featured: [
        {
          name: "New Arrivals",
          href: "/category",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg",
          imageAlt:
            "Drawstring top with elastic loop closure and textured interior padding.",
        },
        {
          name: "Artwork Tees",
          href: "/category",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg",
          imageAlt:
            "Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.",
        },
      ],
      sections: [
        {
          id: "clothing",
          name: "Clothing",
          items: [
            { name: "Tops", href: "/category" },
            { name: "Pants", href: "/category" },
            { name: "Sweaters", href: "/category" },
            { name: "T-Shirts", href: "/category" },
            { name: "Jackets", href: "/category" },
            { name: "Activewear", href: "/category" },
            { name: "Browse All", href: "/category" },
          ],
        },
        {
          id: "accessories",
          name: "Accessories",
          items: [
            { name: "Watches", href: "/category" },
            { name: "Wallets", href: "/category" },
            { name: "Bags", href: "/category" },
            { name: "Sunglasses", href: "/category" },
            { name: "Hats", href: "/category" },
            { name: "Belts", href: "/category" },
          ],
        },
        {
          id: "brands",
          name: "Brands",
          items: [
            { name: "Re-Arranged", href: "/category" },
            { name: "Counterfeit", href: "/category" },
            { name: "Full Nelson", href: "/category" },
            { name: "My Way", href: "/category" },
          ],
        },
      ],
    },
  ],
  pages: [
    { name: "About Us", href: "#" },
    { name: "Stores", href: "#" },
  ],
};

export const products = [
  {
    id: 1,
    name: "C-1000",
    href: "/products/c-1000",
    slug: "c-1000",
    price: "£49.99",
    description: "Boost your immune system and fight off infection with C-1000",
    // options: "8 colors",
    imageSrc:
      "https://commerce.yello.studio/assets/uploads/products/images/Hotpot.png",
    imageAlt: "C-100 product promo shot",
    breadcrumbs: [
      { id: 1, name: "Travel", href: "/category" },
      { id: 2, name: "Bags", href: "/category" },
    ],
    sizes: [
      {
        name: "18L",
        description: "Perfect for a reasonable amount of snacks.",
      },
      {
        name: "20L",
        description: "Enough room for a serious amount of snacks.",
      },
    ],
  },
  {
    id: 2,
    name: "D-Rite",
    href: "/products/d-rite",
    slug: "d-rite",
    price: "£59.99",
    description: "Get the vitamin D you need to stay healthy.",
    options: "200g",
    imageSrc:
      "https://commerce.yello.studio/assets/uploads/products/images/Hotpot-2.png",
    imageAlt: "D-Rite product promo shot",
    breadcrumbs: [
      { id: 1, name: "Travel", href: "/category" },
      { id: 2, name: "Bags", href: "/category" },
    ],
    sizes: [
      {
        name: "18L",
        description: "Perfect for a reasonable amount of snacks.",
      },
      {
        name: "20L",
        description: "Enough room for a serious amount of snacks.",
      },
    ],
  },
  {
    id: 3,
    name: "Nutrient Boost",
    href: "/products/nutrient-boost",
    slug: "nutrient-boost",
    price: "£24.99",
    description: "Get the nutrients you need, naturally.",
    options: "2 sizes",
    imageSrc:
      "https://commerce.yello.studio/assets/uploads/products/images/Hotpot-8.png",
    imageAlt: "Nutrient Boost product promo shot",
    breadcrumbs: [
      { id: 1, name: "Travel", href: "/category" },
      { id: 2, name: "Bags", href: "/category" },
    ],
    sizes: [
      {
        name: "18L",
        description: "Perfect for a reasonable amount of snacks.",
      },
      {
        name: "20L",
        description: "Enough room for a serious amount of snacks.",
      },
    ],
  },
  // More products...
];

export const cartProducts = [
  {
    id: 1,
    name: "C-1000",
    href: "/products/c-1000",
    // color: "Salmon",
    price: "£49.99",
    quantity: 1,
    imageSrc:
      "https://commerce.yello.studio/assets/uploads/products/images/Hotpot.png",
    imageAlt: "C-100 product cart thumbnail",
  },
  {
    id: 2,
    name: "Nutrient Boost",
    href: "/products/nutrient-boost",
    // color: "Blue",
    price: "£49.98",
    quantity: 2,
    imageSrc:
      "https://commerce.yello.studio/assets/uploads/products/images/Hotpot-8.png",
    imageAlt: "Nutrient Boost product cart thumbnail",
  },
  // More products...
];
