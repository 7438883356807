import FAQ from '../components/Faq'
import Footer from '../components/Footer'
import LogoCloud from '../components/LogoCloud'
import Nav from '../components/Nav'
import ProductFeatureSplit from '../components/ProductFeatureSplit'
import ProductFeatureTabs from '../components/ProductFeatureTabs'
import ProductOverview from '../components/ProductOverview'

import { products } from '../data/mock'

import { useParams } from 'react-router-dom'

export default function PageProduct() {

  const { slug } = useParams()
  const product = products.find(product => product.slug == slug)

  return (
    <div className="bg-white">

      <Nav />

      <ProductOverview product={product} />

      <ProductFeatureTabs />

      <ProductFeatureSplit />

      <FAQ />

      <LogoCloud />

      <Footer />
    </div>
  )
}
