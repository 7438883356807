import CategoryFilter from '../components/CategoryFilter'
import Footer from '../components/Footer'
import Incentive from '../components/Incentive'
import Nav from '../components/Nav'
import ProductList from '../components/ProductList'
import Promo from '../components/Promo'

export default function PageCategory() {

  return (
    <div className="bg-white">

      <Nav />

      <CategoryFilter />

      <ProductList />

      <Incentive />

      <Promo />

      <Footer />
    </div>
  )
}
