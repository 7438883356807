import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PageHome from "./pages/Home";
import PageCategory from "./pages/Category";
import PageProduct from "./pages/Product";
import PageCheckout from "./pages/Checkout";
import PageConfirmation from "./pages/Confirmation";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/category" element={<PageCategory />} />
        <Route path="/products/:slug" element={<PageProduct />} />
        <Route path="/checkout" element={<PageCheckout />} />
        <Route path="/confirmation" element={<PageConfirmation />} />
        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
